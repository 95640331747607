<template>
    <ImageIcon :src="imgUrl" :title="data.title" :alt="data.filename ? data.filename : ''" :extension="data.extension" v-if="!loading && imgUrl!== ''" :style="style" :class="props.class" :userImage="userImage" :imageErrorOccured="imageLoadError"/>
    <SkelatonVue v-else :style="style" :class="props.class"/>
</template>

<script setup>
    import { onMounted, defineProps, inject, ref, defineEmits, watch } from 'vue';
    import ImageIcon from "@/components/atom/ImageIcon/ImageIcon.vue"
    import * as env from '@/config/env';
    import isEqual from "lodash/isEqual"
    import SkelatonVue from '../Skelaton/Skelaton.vue';
    import { apiRequest, apiRequestWithoutCompnay } from '../../../services';
    const defaultUserAvatar = inject("$defaultUserAvatar")
    const defaultImage = require("@/assets/images/image_name.png")
    // eslint-disable-next-line
    const companyId = inject('$companyId');  
    const props = defineProps({
        data: {
            type: Object,
            required: true,
        },
        class: {},
        companyId: {
            type: String,
            default: ""
        },
        style: {},
        thumbnail: {
            type: String,
            default: ""
        },
        userImage: {
            type: Boolean,
            default: false
        }
    });

    const emit = defineEmits(["downloadUrl"]);
    const imageObject = ref();
    const imgUrl = ref("");
    const loading = ref(false);
    const tuhumbnailSize = ref(props.thumbnail);
    const isthumbnailOriginal = ref(false);
    onMounted(() => {
        imageObject.value = JSON.parse(JSON.stringify(props.data))
        getImage();
    })

    watch(() => props.data, (newVal, oldVal) => {
        if(!isEqual(newVal, oldVal)) {
            imageObject.value = JSON.parse(JSON.stringify(props.data))
            getImage(newVal.url !== oldVal.url);
            isthumbnailOriginal.value = false;
        }
    })
    watch(() => props.thumbnail,(thumb) => {
        tuhumbnailSize.value = thumb;
        if(thumb){
            getImage();
        }
        isthumbnailOriginal.value = false;
    })
    function getImage(isLoad = true,isThumbnailRetry = false) {
        if(isLoad){
            loading.value = true;
        }
        const cid = props.companyId?.length ? props.companyId : companyId.value;
        let path = ''
        if (tuhumbnailSize.value && !isThumbnailRetry) {
            const [filename, extension] = props.data.url.split(".");
            path = `${filename}-${tuhumbnailSize.value}.${extension}`;
        } else {
            path = props.data.url
        }
        if (path && path.includes("base64")) {
            if(props.userImage){
                imgUrl.value = props.data.url;
                return loading.value = false
            }else{
                imgUrl.value = path;
                if(imgUrl.value.includes('undefined')){
                    imgUrl.value = imgUrl.value.slice(0, imgUrl.value.length - 18)
                }
                return loading.value = false;
            }
        }
        const formData = {
            companyId: cid,
            path: path
        }
        let url = props.userImage ? env.WASABI_RETRIVE_USER_PROFILE : env.WASABI_RETRIVE_OBJECT;
        let reqAPi;
        if (props.userImage) {
            reqAPi = apiRequestWithoutCompnay("post", url, formData);

        } else {
            if (formData.path) {
                reqAPi = apiRequest("post", url, formData);
            }else{
                return loading.value = false;
            }
        }
        reqAPi.then((response)=>{
            if (response.data.status === false) {
                return loading.value = false;
            }
            if(response.data.status === true){
                imageObject.value.downloadUrl = response.data.statusText;
                emit("downloadUrl",response.data.statusText)
                imgUrl.value = response.data.statusText;
                loading.value = false;
            } else {
                loading.value = false;
            }
        }).catch((err)=>{
            loading.value = false;
            console.error(err,"error in get image data");
        })
    }

    function imageLoadError() {
        if (tuhumbnailSize.value) {
            if (isthumbnailOriginal.value == false) {
                getImage(true,true);
                isthumbnailOriginal.value = true;
            } else {
                if (props.userImage) {
                    imgUrl.value = defaultUserAvatar
                } else {
                    imgUrl.value = defaultImage
                }
            }
        } else {
            if (props.userImage) {
                imgUrl.value = defaultUserAvatar
            } else {
                imgUrl.value = defaultImage
            }
        }
    }
</script> 